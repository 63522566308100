import { fromEvent, map, merge, mergeMap } from 'rxjs'
import { elementWaiter } from './elementWaiter'

export interface CodelessEventListener {
  sitePk: string
  eventListenerSk: string
  pageUrl: string
  name: string
  selectors: Record<string, Record<string, boolean>>
}

export interface CodelessEvent {
  sessionId: string
  eventListenerSk: string
  type: string
  selector: string
  timestamp: number
}

// Very basic, just listens to events on the page and emits them
// The events are given by the metrics endpoint
export function createCodelessEventsObservable(eventListeners: CodelessEventListener[], sessionId: string) {
  const observables = []

  for (const { selectors, eventListenerSk } of eventListeners) {
    for (const [selector, typesObject] of Object.entries(selectors)) {
      for (const type of Object.keys(typesObject as Record<string, true>)) {
        // console.log('Listening for', type, 'on', selector)

        const elementObservable = elementWaiter.waitFor(selector).pipe(
          mergeMap(($element) => {
            // console.log('Found element', $element, 'for selector', selector, 'and type', type)
            const eventListener$ = fromEvent($element, type)

            return eventListener$.pipe(
              map((): CodelessEvent => ({ sessionId, eventListenerSk, type, selector, timestamp: Date.now() })),
            )
          }),
        )

        observables.push(elementObservable)
      }
    }
  }
  // console.log('Observables here', observables)
  const allEvents$ = merge(...observables)
  // console.log('All events observable', allEvents$)

  return allEvents$
}
