export const enum Platform {
  WINDOWS = 'Windows',
  MAC = 'Mac',
  LINUX = 'Linux',
  ANDROID = 'Android',
  CHROME_OS = 'Chrome OS',
}

export const SYSTEM_FONTS = [
  'caption',
  'icon',
  'menu',
  'message-box',
  'small-caption',
  'status-bar',
]
