import { Observable } from 'rxjs'

class ElementWaiter {
  mutationObserver?: MutationObserver
  callbacks: Map<string, Set<(element: Element) => void>> = new Map()

  constructor() {
    this.callbacks = new Map()
    this.makeObserver()
  }

  makeObserver() {
    this.mutationObserver = new MutationObserver(() => {
      for (const [selector, callbacks] of this.callbacks.entries()) {
        const element = document.querySelector(selector)
        if (element) {
          this.callbacks.delete(selector)
          for (const callback of callbacks)
            callback(element)
        }
      }
      if (this.callbacks.size === 0)
        this.mutationObserver!.disconnect()
    })

    // console.log(document.body)
    if (!document.body) {
      window.setTimeout(this.makeObserver, 500)
      return
    }

    this.mutationObserver.observe(document.body, {
      childList: true,
      subtree: true,
    })
  }

  waitFor(selector: string): Observable<Element> {
    return new Observable((observer) => {
      const element = document.querySelector(selector)
      if (element) {
        observer.next(element)
        observer.complete()
        return
      }

      const callbacks = this.callbacks.get(selector) || new Set()
      callbacks.add((element) => {
        observer.next(element)
        observer.complete()
      })
      this.callbacks.set(selector, callbacks)
    })
  }
}

export const elementWaiter = new ElementWaiter()

// export function waitForEl(selector: string): Observable<Element> {
//   return new Observable((observer) => {
//     const element = document.querySelector(selector)
//     if (element) {
//       observer.next(element)
//       observer.complete()
//       return
//     }

//     const mutationObserver = new MutationObserver(() => {
//       const element = document.querySelector(selector)
//       if (element) {
//         mutationObserver.disconnect()
//         observer.next(element)
//         observer.complete()
//       }
//     })

//     mutationObserver.observe(document.body, {
//       childList: true,
//       subtree: true,
//     })

//     // Handle unsubscription
//     return () => mutationObserver.disconnect()
//   })
// }
