import type { MonoTypeOperatorFunction, Observable } from 'rxjs'
import { buffer, filter, interval, startWith, switchMap } from 'rxjs'

export function emitOnNth<T>(n: number): MonoTypeOperatorFunction<T> {
  return filter((_, index) => n === index)
}

// Create a buffer that executes every 10_000 ms and every time a session-ending event occurs
// x--10--10-x--10-x-x--10--10--10--10
// Where every x is a danger event and every 10 is an interval elapsing
// RestartBufferInterval can emit any value. This doesn't care what it is, because it destroys it
export function makeIntervalBufferer<T>(restartBufferInterval$: Observable<any>, i: number) {
  return buffer<T>(restartBufferInterval$.pipe(switchMap(() => interval(i).pipe(startWith(0)))))
}
