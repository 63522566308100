import { isHTMLElement } from './isHTMLElement'
import { proxyURL } from './proxyUrl'

enum MutationType {
  Attributes = 0, // 'attributes',
  CharacterData = 1, // 'characterData',
  ChildList = 2, // 'childList',
}

// id, nodeName, attributes, children?
// id, nodeValue
type NodePosition = [previousSibling: number, nextSibling: number]
export interface SerializedAttributes {
  [key: string]: string
}
export type SerializedElement =
  | readonly [id: number, string, SerializedAttributes, SerializedNode[], NodePosition?]
  | readonly [id: number, string, SerializedAttributes, NodePosition?]
export type SerializedTextNode = readonly [id: number, content: string, NodePosition?]
export type SerializedNode = SerializedElement | SerializedTextNode

export type SerializedChildListMutation = readonly [
  number,
  MutationType.ChildList,
  readonly SerializedNode[],
  readonly number[],
    any?,
]
export type SerializedCharacterDataMutation = readonly [
  number,
  MutationType.CharacterData,
  string,
    any?,
]
export type SerializedAttributesMutation = readonly [
  number,
  MutationType.Attributes,
  string,
  string,
    any?,
]
export type SerializedMutation =
  | SerializedChildListMutation
  | SerializedCharacterDataMutation
  | SerializedAttributesMutation

interface NtagNode extends Node {
  ntagId?: number
}
export class DOMSerializer {
  private currentId: number
  private nodeToId: WeakMap<Node, number>
  constructor() {
    this.currentId = 0
    this.nodeToId = new WeakMap()
  }

  private indexNode(node: NtagNode) {
    const id = this.currentId++
    this.nodeToId.set(node, id)
    node.ntagId = id
    return id
  }

  public getNodeId(node: NtagNode) {
    // If node already indexed, return existing ID, else index the node
    return (
      node.ntagId
      || (this.nodeToId.has(node)
        ? this.nodeToId.get(node)!
        : this.indexNode(node))
    )
  }

  public serializeNode(
    node: Node,
        removeTextNodes = false,
  ): SerializedNode | null {
    // Filter out bad elements
    // if (node.nodeName === 'SCRIPT' || node.nodeName === 'STYLE')
    //   return null
    if (node.nodeType === Node.COMMENT_NODE || node.nodeName === 'SCRIPT')
      return null

    const id = this.getNodeId(node)

    // Special handling for text nodes
    if (node.nodeType === Node.TEXT_NODE) {
      if (
        !node.nodeValue
        || (removeTextNodes && /^\s+$/.test(node.nodeValue))
      ) {
        return null
      }
      else {
        return [id, node.nodeValue.replace(/\s+/, ' ')] as const
      } // [id, nodeValue]
    }

    // Special handling for SVG elements
    if (node instanceof SVGElement)
      return [id, node.outerHTML] // [id, SVG as string]

    // const serialized: readonly [
    //   number,
    //   string,
    //   { [key: string]: string },
    //   any[],

    // ] = [id, node.nodeName, {}, []] // ["id", "nodeName", {attributes}?, [children]?]

    const attributes: SerializedAttributes = {}
    const children: SerializedNode[] = []
    const position: NodePosition = [node.previousSibling ? this.getNodeId(node.previousSibling) : -1, node.nextSibling ? this.getNodeId(node.nextSibling) : -1]
    // Serialize attributes
    if (
      node instanceof Element
      && node.attributes
      && node.attributes.length
    ) {
      // serialized[] = {} // Add attributes object
      for (const attr of node.attributes) {
        // Adjust this list of attributes as needed
        // if (
        //   [
        //     'id',
        //     'class',
        //     'name',
        //     'value',
        //     'type',
        //     'href',
        //     'rel',
        //     'charset',
        //     'dir',
        //     'integrity',
        //     'src',
        //     'onload',
        //     'async',
        //     'crossorigin',
        //     '',
        //   ].includes(attr.name)
        // )
        attributes[attr.name] = attr.value
      }
    }

    for (const child of node.childNodes) {
      const serializedChild = this.serializeNode(
        child,
        removeTextNodes || node.nodeName === 'HEAD',
      ) // remove text nodes of all HEAD descendants
      if (serializedChild)
        children.push(serializedChild) // Add serialized child to children array
    }

    return [id, node.nodeName, attributes, children, position] as const
  }

  public serializeMutation(mutation: MutationRecord): SerializedMutation {
    const targetId = this.getNodeId(mutation.target)
    const addedNodes = Array.from(mutation.addedNodes)
      .map(node => this.serializeNode(node))
      .filter((node): node is SerializedNode => node !== null)
    const removedNodes = Array.from(mutation.removedNodes).map(
      this.getNodeId.bind(this),
    )

    switch (mutation.type) {
      case 'attributes':
        // Calmate, this is going to have attributes
        return [
          targetId,
          MutationType.Attributes,
          mutation.attributeName as string,
          (mutation.target as Element).getAttribute(
            mutation.attributeName as string,
          ) as string,
          {
            nodeName: mutation.target.nodeName,
            type: mutation.target.nodeType,
          },
        ] as const
      case 'characterData':
        return [
          targetId,
          MutationType.CharacterData,
          mutation.target.nodeValue as string,
          {
            nodeName: mutation.target.nodeName,
            type: mutation.target.nodeType,
          },
        ] as const
      case 'childList': {
        const nextSibling = mutation.nextSibling
          ? this.getNodeId(mutation.nextSibling)
          : null
        const previousSibling = mutation.previousSibling
          ? this.getNodeId(mutation.previousSibling)
          : null
        return [
          targetId,
          MutationType.ChildList,
          addedNodes,
          removedNodes,
          {
            nodeName: mutation.target.nodeName,
            type: mutation.target.nodeType,
            nextSibling,
            previousSibling,
          },
        ] as const
      }
    }
  }
}

export class DOMDeserializer {
  private idToNode: Map<number, Node>
  private problemChildren: Record<number, SerializedAttributesMutation> = {}
  constructor(public document: Document) {
    // console.log('DOMDeserializer: Constructing')
    this.idToNode = new Map()
  }

  public getNodeById(id: number) {
    // if (id == 493) console.log("DOMDeserializer: Getting node by ID", id, this.idToNode.get(id))
    // console.log("Getnodebyid:", this.document.contains(this.idToNode.get(id) ?? null))
    return (
      this.idToNode.get(id)
      ?? this.document.querySelector(`[data-id="${id}"]`)
      ?? null
    )
  }

  private indexNode(id: number, node: Node) {
    // if (id == 493) console.log("DOMDeserializer: Setting node by ID", id, node)
    // console.log("DOMSerializer: Serializing " + id + " as " + node.nodeName)
    if (isHTMLElement(node))
      node.setAttribute('data-id', id.toString())
    this.idToNode.set(id, node)
  }

  private deserializeChildren(
    node: ParentNode,
    children: SerializedNode[] | undefined,
  ) {
    if (children && children.length) {
      for (const serializedChild of children) {
        const [id] = serializedChild
        const existingChild = this.getNodeById(id)
        const childBelongsToParent
                    = existingChild && existingChild.parentNode === node
        const child = this.deserialize(serializedChild, node)
        if (childBelongsToParent && existingChild.parentNode !== node) {
          // debugger;
          if (existingChild === null)
            node.appendChild(child)
        }
        // if (serializedChild)
        //   serialized[3].push(serializedChild) // Add serialized child to children array
      }
    }
  }

  private proxyURL(url: string) {
    // if (
    //   !/(?:https?:)?\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(
    //     url,
    //   )
    // )
    //   return url;
    // if (url.startsWith("//")) url = "https:" + url; // TODO: make this dependent on whatever the current page protocol is
    // if (url.includes("p.nlytics.co")) return url;
    // // debugger;
    // const proxy = new URL(url);
    // proxy.host = proxy.protocol.slice(0, -1) + "--" + proxy.host.replaceAll(".", "-") + ".p.nlytics.co";
    // proxy.protocol = "https:";
    // return proxy.toString();
    return proxyURL(url)
  }

  // Hacky name, could be used for body as well
  private proxyAttribute(attribute: string, value: string) {
    // // console.log("Proxying attribute", attribute, value)
    // const node = this.getNodeById(Number(attribute))
    // if (node) {
    //     // console.log("Proxying attribute", attribute, value, "to", node)
    //     node.setAttribute("data-id", value)
    // }
    // Only url attributes are proxied
    switch (attribute) {
      case 'class':
      case 'id':
        return value
      case 'src':
      case 'href':
      case 'action':
        return this.proxyURL(value)
      default: // in the case of scrset or the like
        return value.replace(
          /(?:https?:)?\/\/(www\.)?[-\w@:%.+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-\w()@:%+.~#?&/=]*)/g,
          (match) => {
            return this.proxyURL(match)
          },
        )
    }
  }

  public deserialize(
    serialized: SerializedNode,
    parentNode?: ParentNode,
  ): Node {
    // const [id, type, value] = serialized;
    const [id, ...rest] = serialized
    // if (id === 950) console.log("Deserializing", serialized);
    let node: Node | null = this.getNodeById(id)
    const existingNodeNames = ['BODY', 'HEAD', 'HTML']
    // const existingNode = existingNodeNames.includes(node?.nodeName ?? "");
    if (existingNodeNames.includes(serialized[1])) {
      // console.log('Deserializing', serialized, node, parentNode)
      node = this.document.getElementsByTagName(serialized[1])[0]
      // node = this.document.body;
    }
    // if (node) {
    //   console.debug("Deserializer found existing node pending serialize:", this.document.contains(node), node);
    // }
    if (rest.length === 1) {
      const [text] = rest
      // It's a text node or an svg node
      if (/<svg/.test(text)) {
        // It's SVG
        // node = document.createElementNS()
        // Hee hee
        // dummy
        // console.log(dummyTemplate, text)

        // node = dummyTemplate.content.firstChild!;
        // const parser = new (this.document.defaultView?.DOMParser || DOMParser)();

        // const svgDoc = parser.parseFromString(text, "image/svg+xml");
        // debugger;

        if (!node) {
          const dummyTemplate
                        = this.document.createElement('template')
          this.document.body.appendChild(dummyTemplate)
          dummyTemplate.innerHTML = text
          node = this.document.importNode(
            dummyTemplate.content.firstChild!,
            true,
          )
          dummyTemplate.content.removeChild(
            dummyTemplate.content.firstChild!,
          )
          dummyTemplate.remove()
          // node = this.document.createElement("svg")
        }
      }
      else {
        if (node) {
          node.textContent = text
        }
        else {
          node = this.document.createTextNode(
            (parentNode && ['SCRIPT', 'STYLE'].includes(parentNode.nodeName))
              ? this.proxyAttribute('text', text)
              : text,
          )
        }
      }
      this.indexNode(id, node)
    }
    else {
      const [nodeName, serializedAttributes, children] = rest
      if (!node)
        node = this.document.createElement(nodeName)

      if (isHTMLElement(node)) {
        // debugger;
        for (const [k, v] of Object.entries(serializedAttributes))
          node.setAttribute(k, this.proxyAttribute(k, v))

        // espesyal pandesal
        // node.setAttribute("data-id", id.toString());
        this.indexNode(id, node)
        // console.log("Node ID is ", node, "#" + node.getAttribute("data-id"))
        // console.log("Node indexed ID is ", this.getNodeById(id))
        // console.log('Deserializing children', children, node)
        this.deserializeChildren(node, children)
      }
    }
    return node
  }

  public applyMutation(serializedMutation: SerializedMutation) {
    const [targetId, type, ...rest] = serializedMutation

    const targetNode = this.getNodeById(targetId)

    if (!targetNode) {
      console.warn(
        'Target node not found for mutation',
        serializedMutation,
      )
      return
    }

    switch (type) {
      case MutationType.Attributes: {
        if (isHTMLElement(targetNode)) {
          const [attribute, value] = rest
          targetNode.setAttribute(
            attribute as string,
            value as string,
          )
        }
        else {
          // console.warn(
          //     "Replayer: Target node not an HTMLElement for attribute mutation",
          //     serializedMutation
          // );
          this.problemChildren[targetId] = serializedMutation
          // console.log("Replayer: problem children", this.problemChildren)
        }
        break
      }
      case MutationType.CharacterData: {
        const [nodeValue] = rest
        targetNode.nodeValue = nodeValue as string
        break
      }
      case MutationType.ChildList: {
        if (!isHTMLElement(targetNode)) {
          console.warn(
            'Replayer: Target node not an HTMLElement for child list mutation',
            serializedMutation,
            targetNode,
          )
          return
        }
        const [children, deletedIds] = rest
        this.deserializeChildren(
          targetNode,
          children as SerializedNode[],
        )
        for (const id of deletedIds || []) {
          const nodeToRemove = this.getNodeById(Number(id))
          if (id && nodeToRemove) {
            if (
              isHTMLElement(nodeToRemove)
              && nodeToRemove.remove
            ) {
              nodeToRemove.remove()
            }
            else {
              try {
                targetNode.removeChild(nodeToRemove)
              }
              catch (e) {
                nodeToRemove.parentNode?.removeChild(
                  nodeToRemove,
                )
                console.warn(
                  'Replayer: Cannot remove child',
                  id,
                  'from',
                  targetId,
                  nodeToRemove,
                  e,
                )
                // if (e instanceof NotFoundError) {
                // } else {
                //     throw e
                // }
              }
            }
            this.idToNode.delete(Number(id)) // free up memory
          }
        }
        break
      }
    }
  }
}
